import * as React from 'react';
import { StarIcon } from './Icons';
import { AppColors } from 'src/main/Theme';

const ProBadge = ({ small, inv }: { small?: boolean, inv?: boolean }) => {
    return (
        <span style={{
            color: inv ? AppColors.webGreen500 : AppColors.white,
            backgroundColor: inv ? AppColors.white : AppColors.webGreen300,
            borderRadius: small ? 10 : 14,
            fontSize: small ? 11 : 12,
            fontWeight: 500,
            height: small ? 20 : 28,
            width: small ? 48 : 60,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'none'
        }}>
            <StarIcon sx={{ width: small ? '14px' : '16px', paddingRight: '4px' }} />
            <span>Pro</span>
        </span>
    );
}

export default ProBadge;
