import * as React from 'react';
import { ButtonGroup, Grid, Typography } from '@mui/material';
import { useAppStyles } from 'src/styles';
import { AppColors } from 'src/main/Theme';
import { useUserAware } from './Auth';
import AppButton from 'src/common/components/AppButton';
import { Flex, FlexCenter, Spacing } from 'src/common/Misc';
import { DoneIcon } from 'src/common/Icons';
import { PRICE_MONTHLY as PRO_PRICE_MONTHLY, PRICE_YEARLY as PRO_PRICE_YEARLY } from 'src/util/config';
import ProBadge from 'src/common/ProBadge';
import { FREE_OPTS, PRO_OPTS, PRO_OPTS_EXT } from 'src/util/strings';

export default function AccountPlans({ variant, action }: { variant: 'short' | 'long', action: (name: 'learn_more' | 'upgrade') => void }) {
    const [yearly, setYearly] = React.useState(true);
    const classes = useAppStyles();
    const userAware = useUserAware();
    const pro = userAware.hasPro;
    const freeOpts = FREE_OPTS;
    const proOpts = variant === 'long' ? PRO_OPTS.concat(PRO_OPTS_EXT) : PRO_OPTS;
    const payModeButtons = <ButtonGroup size="small">
        <AppButton
            sx={{
                width: 60,
                borderRadius: '6px',
                color: yearly ? AppColors.white : AppColors.webGreen500,
                border: `1px solid ${AppColors.webGreen500}`,
                backgroundColor: yearly ? AppColors.webGreen500 : AppColors.white,
                '&:hover': {
                    backgroundColor: yearly ? AppColors.webGreen600 : AppColors.webGreen100,
                }
            }}
            onClick={() => setYearly(true)}>
            Yearly
        </AppButton>
        <AppButton
            sx={{
                width: 68,
                borderRadius: '6px',
                color: !yearly ? AppColors.white : AppColors.webGreen500,
                border: `1px solid ${AppColors.webGreen500}`,
                backgroundColor: !yearly ? AppColors.webGreen500 : AppColors.white,
                '&:hover': {
                    backgroundColor: !yearly ? AppColors.webGreen600 : AppColors.webGreen100,
                }
            }}
            onClick={() => setYearly(false)}>
            Monthly
        </AppButton>
    </ButtonGroup>;
    return (
        <div style={{ width: '100%' }}>
            <div style={{ textAlign: 'center' }} className={classes.listRootGrey}>
                <FlexCenter>
                    <Typography sx={{ fontSize: 24, fontWeight: 500, maxWidth: variant === 'short' ? 430 : undefined }}>
                        Upgrade to Pro — Get Unlimited Experience and Priority Support
                    </Typography>
                </FlexCenter>
                {variant === 'long' && <>
                    <Typography sx={{ fontSize: 16, fontWeight: 400, color: AppColors.webGrey400 }}>
                        Use Events Pro to organize great events on a professional level
                    </Typography>
                    <Spacing />
                    <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                        <span style={{ color: AppColors.webGreen300, fontWeight: 600 }}>Save 57%</span> on a yearly subscription
                    </Typography>
                    <Spacing height={4} />
                    {payModeButtons}
                </>}
                <Spacing />
                <FlexCenter>
                    <Grid container className={classes.settingsPlanRoot}>
                        <Grid item sm={12} md={6} className={classes.settingsPlanTab}>
                            <div className={classes.settingsPlanTabContent}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 300 }}>
                                    <div>
                                        <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Free plan</Typography>
                                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>Free</Typography>
                                        {freeOpts.map((opt, idx) => <Flex key={idx}>
                                            <DoneIcon sx={{ color: AppColors.white, backgroundColor: AppColors.webGrey200 }} className={classes.roundIcon} />
                                            <Typography key={idx}>{opt}</Typography>
                                        </Flex>)}
                                    </div>
                                    <div>
                                        {pro ?
                                            <AppButton style={{ width: '100%', backgroundColor: AppColors.webGrey400 }}>
                                                Unsubscribe
                                            </AppButton> :
                                            <div>
                                                <Typography style={{ backgroundColor: AppColors.webGrey150, textAlign: 'center', padding: 6, borderRadius: 20 }}>
                                                    You are on free plan
                                                </Typography>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item sm={12} md={6} className={classes.settingsPlanTab}>
                            <div className={classes.settingsPlanTabContent} style={{ backgroundColor: AppColors.webGreen100 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 300 }}>
                                    <div>
                                        <Typography sx={{ fontSize: 20, fontWeight: 500 }} component={'span'}>
                                            <Flex>
                                                Events Pro &nbsp;
                                                <ProBadge />
                                                <span style={{ flex: '1 1 0%' }} />
                                                {variant === 'short' && payModeButtons}
                                            </Flex>
                                        </Typography>
                                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                                            US${yearly ? (PRO_PRICE_YEARLY / 12).toFixed(2) : PRO_PRICE_MONTHLY}<span style={{ color: AppColors.webGrey400, fontWeight: 400 }}>/month</span>
                                        </Typography>
                                        {proOpts.map((opt, idx) => <Flex key={idx}>
                                            <DoneIcon sx={{ color: AppColors.white, backgroundColor: AppColors.webGreen300 }} className={classes.roundIcon} />
                                            <Typography key={idx}>{opt}</Typography>
                                        </Flex>)}
                                        <Flex>
                                            <DoneIcon sx={{ color: AppColors.webGreen500, backgroundColor: AppColors.white }} className={classes.roundIcon} />
                                            <Typography>New features coming soon</Typography>
                                        </Flex>
                                    </div>
                                    <div>
                                        {pro ?
                                            <div>
                                                <Typography style={{ backgroundColor: AppColors.white, color: AppColors.webGreen600, fontWeight: 600, textAlign: 'center', padding: 6, borderRadius: 20 }}>
                                                    You are on Pro plan
                                                </Typography>
                                            </div> : variant === 'short' ?
                                                <>
                                                    <AppButton
                                                        style={{ width: '100%', backgroundColor: AppColors.webGreen500 }}
                                                        onClick={() => action('learn_more')}>
                                                        Learn more
                                                    </AppButton>
                                                    <Spacing height={4} />
                                                    <AppButton
                                                        style={{ width: '100%', backgroundColor: AppColors.white, color: AppColors.webGreen500, border: `1px solid ${AppColors.webGreen500}` }}
                                                        onClick={() => action('upgrade')}>
                                                        Upgrade
                                                    </AppButton>
                                                </> : <>
                                                    <AppButton
                                                        style={{ width: '100%', backgroundColor: AppColors.webGreen500 }}
                                                        onClick={() => action('upgrade')}>
                                                        Upgrade
                                                    </AppButton>
                                                </>}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </FlexCenter>
                <Spacing />
                {variant === 'long' && <>
                    <FlexCenter>
                        <Typography sx={{ fontSize: 14, fontWeight: 400, maxWidth: 430 }}>
                            Still have questions? Contact us and we will answer about how you can use Events Pro to your advantage
                        </Typography>
                    </FlexCenter>
                    <Spacing />
                    <AppButton color="inherit">
                        Contact us
                    </AppButton>
                    <Spacing />
                </>}
            </div>
        </div>
    );
}
