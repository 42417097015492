import * as React from 'react';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import ReactQuill from 'react-quill';
import { WithStyles, makeStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import ImageResize from './quill-img-resize/ImageResize';
import ImageBlot from './quill-img-resize/ImageBlot';
import { theme } from '../../../main/Theme';
import { styles } from '../../../styles';
import * as Backend from '../../../util/firebase';
import { hideAlert, showAlertProps, showProgress } from '../../../redux/ReduxConfig';
import AppButton from 'src/common/components/AppButton';
import { processEnterKey } from 'src/util/react_utils';

Quill.register('modules/imageResize', ImageResize);
Quill.register(ImageBlot, true);

const Delta = Quill.import('delta');
const Embed = Quill.import('blots/block/embed');
const Icons = Quill.import('ui/icons');
const Inline = Quill.import('blots/inline');

interface LinkFormat {
    href: string;
    target: string;
}

function isStringified(value: string): boolean {
    try {
        JSON.parse(value);
    } catch (e) {
        return false;
    }
    return true;
}

class LinkBlot extends Inline {
    static blotName = 'link';
    static tagName = 'a';

    static create(value: string) {
        const node = super.create() as HTMLAnchorElement;
        let newValue: string | LinkFormat;
        if (isStringified(value)) {
            newValue = JSON.parse(value);
        } else {
            newValue = value;
        }
        if (typeof newValue !== 'string') {
            node.setAttribute('href', newValue.href);
            node.setAttribute('target', newValue.target);
            return node;
        }
        node.setAttribute('href', newValue);
        return node;
    }

    static formats(node: HTMLAnchorElement): string {
        return JSON.stringify({
            href: node.getAttribute('href'),
            target: node.getAttribute('target'),
        });
    }

    format(name: string, value: any): void {
        if (name !== this.statics.blotName || !value) {
            return super.format(name, value);
        }
        let newValue: string | LinkFormat;
        if (isStringified(value)) {
            newValue = JSON.parse(value);
        } else {
            newValue = value;
        }
        if (typeof newValue !== 'string') {
            this.domNode.setAttribute('href', newValue.href);
            this.domNode.setAttribute('target', newValue.target);
        } else {
            this.domNode.setAttribute('href', newValue);
        }
    }
}

Quill.register(LinkBlot);

class Hr extends Embed {
    static blotName = 'hr'; // use .ql-hr classname in your toolbar
    static tagName = 'hr';

    static create(value: any) {
        let node = super.create(value);
        node.setAttribute('style', "height:0px; margin-top:8px; margin-bottom:8px;");
        return node;
    }
}

Quill.register({ 'formats/hr': Hr });

Icons['hr'] = `
<svg width="17px" height="12px" viewBox="0 0 17 12">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="h3" fill="currentColor">
            <rect fill="#ffffff" height="3" stroke="#000000" width="11" x="3" y="5"/>
        </g>
    </g>
</svg>`;

interface QuillToolbar {
    container: HTMLElement;
    quill: Quill;
}

// Copy of the original quill handler modified to upload images to firestore
function imageLoader(this: QuillToolbar) {
    let fileInput = this.container.querySelector('input.ql-image[type=file]') as HTMLInputElement;
    const quill = this.quill;
    if (fileInput == null) {
        fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
        fileInput.classList.add('ql-image');
        fileInput.addEventListener('change', async function() {
            if (!fileInput.files || !fileInput.files[0]) {
                return;
            }
            const hideProgress = showProgress('imageLoader');
            const fileName = Date.now().toString() + '.jpg';
            const metadata = { contentType: 'image/jpeg' };
            const uploadResult = await Backend.uploadBytes(Backend.ref(Backend.portalImagesRef, fileName), fileInput.files[0], metadata);
            const downloadURL = await Backend.getDownloadURL(uploadResult.ref);
            try {
                const range = quill.getSelection(true);
                quill.updateContents(new Delta().retain(range.index).delete(range.length).insert({ image: { src: downloadURL } }), 'user');
                quill.setSelection(range.index + 1, 0, 'silent');
                fileInput.value = '';
                hideProgress();
            } catch (err: any) {
                hideProgress('Image upload failed: ' + err.message)
            }
        });
        this.container.appendChild(fileInput);
    }
    fileInput.click();
}

const useStyles = makeStyles({
    table: {
        border: 'none',
        background: 'none',
        boxShadow: 'none'
    },
    td: {
        border: 'none'
    }
});

export const LinkPrompt = ({ save }: { save: (url: string, target: string) => void }) => {
    const classes = useStyles();
    const [url, setUrl] = React.useState('');
    const [target, setTarget] = React.useState(true);
    const disabled = !url.trim();
    const save1 = () => save(url.trim(), target ? '_blank' : '_self');
    return (
        <div style={{ padding: 10 }}>
            <table className={classes.table}><tbody>
                <tr>
                    <td style={{ padding: 8 }} className={classes.td}>
                        Enter link:
                    </td>
                    <td className={classes.td}>
                        <input type="text" value={url} autoFocus
                            onKeyDown={e => processEnterKey(e, save1, disabled)}
                            onChange={e => setUrl(e.target.value)} />
                    </td>
                    <td className={classes.td}>
                        <AppButton variant="text" color="info"
                            disabled={disabled} onClick={save1}>
                            Save
                        </AppButton>
                    </td>
                </tr>
                <tr>
                    <td className={classes.td} />
                    <td className={classes.td}>
                        <input type="checkbox" id="scales" name="scales" checked={target} onChange={() => setTarget(!target)} />
                        <label htmlFor="scales">Open in new tab</label>
                    </td>
                    <td className={classes.td} />
                </tr>
            </tbody></table>
        </div>
    );
};

function linkEditor(this: QuillToolbar, value: any) {
    const quill = this.quill;
    if (value) {
        const linkPrompt = <LinkPrompt save={(href, target) => {
            quill.format('link', { href, target });
            hideAlert({ content: linkPrompt, ownContent: true, buttons: [] });
        }} />;
        showAlertProps({ content: linkPrompt, ownContent: true, buttons: [] });
    } else {
        quill.format('link', false);
    }
}

function hrEditor(this: QuillToolbar, value: any) {
    const quill = this.quill;
    const range = quill.getSelection(true);
    if (value) {
        quill.insertEmbed(range.index, "hr", "null");
    }
}

const toolbarOptions = {
    container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['hr'],
        ['image'],
        ['link'],
    ],
    handlers: {
        image: imageLoader,
        link: linkEditor,
        hr: hrEditor
    }
};

const modules = {
    toolbar: toolbarOptions,
    imageResize: {}
};

interface RichTextQuillProps {
    value: string;
    onChange: (val: string) => void;
}

class RichTextQuill extends React.Component<RichTextQuillProps & WithStyles<typeof styles>> {
    render() {
        const { classes } = this.props;
        return (
            <ReactQuill className={classes.quillEd} value={this.props.value} style={styles(theme)} onChange={this.props.onChange} modules={modules} />
        );
    }
}

export default withStyles(styles)(RichTextQuill);
