import * as React from 'react';
import { ChangeEvent } from 'react';
import {
    DialogActions,
    DialogContent,
    Grid,
    IconButton, TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { InputLabelProps } from '@mui/material/InputLabel';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Event, EventData, ContactInvite } from '../types/EventTypes';
import ValidatedTextField, { emailValidation, maxLength, ValidatedTextFieldProps } from '../validation/ValidatedTextField';
import withForm, { ValidateProps } from '../validation/ValidatedForm';
import { useUserAware } from '../auth/Auth';
import { emailFormat, formatDateTime, makeFriendlyString, toSafeString } from '../util/utility';
import { processEnterKey } from '../util/react_utils';
import { XSMobileDialog } from '../common/dialog/MobileDialog';
import AppButton from '../common/components/AppButton';
import DialogAppBar from '../common/dialog/DialogAppBar';
import { styles } from '../styles';
import CloseIcon from '@mui/icons-material/Close';

function isValidOrEmptyEmail(email?: string) {
    return !email || emailFormat.test(email);
}

function toContactInvite(data: InviteData): ContactInvite {
    return { ...data } as ContactInvite;
}

function toData(invite?: ContactInvite): InviteData {
    if (!invite) {
        return {} as InviteData;
    }
    return invite as InviteData;
}

interface EditInviteDialogProps {
    open: boolean;
    event: Event;
    eventData: EventData;
    initialInvite: ContactInvite;
    handleClose: () => void;
    handleSave: (contactInvite: ContactInvite, initialInvite?: ContactInvite) => void;
    handleHide?: (contactInvite: ContactInvite) => void;
    handleConfirm?: (contactInvite: ContactInvite) => void;
    handleResend?: (contactInvite: ContactInvite) => void;
}

type CommonProps = EditInviteDialogProps & WithStyles<typeof styles> & ValidateProps;
type Props = EditInviteDialogProps & WithStyles<typeof styles> & ValidateProps & { userId: string };

interface InviteData {
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
    cid?: string;
}

interface State {
    data: InviteData;
    showProgressBar?: boolean;
}

class InviteDialog extends React.Component<Props, State> {

    private controller = new AbortController();

    constructor(props: Props) {
        super(props);
        const { initialInvite } = props;
        this.state = {
            data: toData(initialInvite)
        };
    }

    componentWillUnmount() {
        this.controller.abort();
    }

    private handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        const data = { ...this.state.data };
        const trimValue = e.target.name === 'email';
        const val = toSafeString(e.target.value, trimValue);
        if (this.props.validateField) {
            this.props.validateField(e.target.name, val)
        }
        data[e.target.name as keyof InviteData] = val;
        this.setState({ data });
    };

    private handleSave = () => {
        const { initialInvite, handleSave, validate, valid } = this.props;
        const { data } = this.state;
        if (validate) {
            validate();
        }
        if ((valid && !valid())) {
            return;
        }
        handleSave(toContactInvite(data), initialInvite);
    };

    private handleHide = () => {
        const { handleHide, validate, valid } = this.props;
        const { data } = this.state;
        if (!handleHide) {
            return;
        }
        if (validate) {
            validate();
        }
        if ((valid && !valid())) {
            return;
        }
        handleHide(toContactInvite(data));
    };

    private handleConfirm = () => {
        const { handleConfirm, validate, valid } = this.props;
        const { data } = this.state;
        if (!handleConfirm) {
            return;
        }
        if (validate) {
            validate();
        }
        if ((valid && !valid())) {
            return;
        }
        handleConfirm(toContactInvite(data));
    };

    private handleResend = () => {
        const { handleResend, validate, valid } = this.props;
        const { data } = this.state;
        if (!handleResend) {
            return;
        }
        if (validate) {
            validate();
        }
        if ((valid && !valid())) {
            return;
        }
        handleResend(toContactInvite(data));
    };

    private handleClose = (_uiEvent: string, reason: string) => {
        if ('backdropClick' === reason) {
            return;
        }
        this.props.handleClose();
    };

    render() {
        const { open, event, classes } = this.props;
        const headerName = this.isInEditMode() ? 'EDIT INVITED CONTACT INFO' : 'ADD INVITED CONTACT INFO';
        return <>
            <XSMobileDialog open={open} onClose={this.handleClose} onKeyDown={e => processEnterKey(e, this.handleSave)}>
                <DialogAppBar appBarStyle={{ padding: 16, backgroundColor: '#5893c7' }}>
                    <Typography className={classes.bigHeaderFontStyle}>
                        {headerName}
                        <IconButton sx={{ float: 'right' }} onClick={this.props.handleClose} color="inherit" size="large">
                            <CloseIcon />
                        </IconButton>
                    </Typography>
                    <Typography className={`${classes.contactDialogEventName} ${classes.smallTextLabel}`}>{event.name}</Typography>
                </DialogAppBar>
                <DialogContent sx={{ paddingTop: 0 }}>
                    <div className={classes.body}>
                        <this.MainInfoComponent />
                    </div>
                </DialogContent>
                <DialogActions>
                    <this.ActionsComponent />
                </DialogActions>
            </XSMobileDialog>
        </>;
    }

    public ActionsComponent = () => {
        const { initialInvite, handleClose, eventData } = this.props;
        const { data } = this.state;
        const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
        const canConfirm = !!initialInvite?.id && (initialInvite?.inviteStatus === 'invite_sent' || initialInvite?.inviteStatus === 'invite_resent');
        const canEdit = (initialInvite?.inviteStatus == 'invite_sent' || initialInvite?.inviteStatus == 'invite_resent') && !eventData.roster.has(initialInvite.id)
        return <>
            {canConfirm && this.props.handleResend && <AppButton color="info" onClick={this.handleResend}>Resend</AppButton>}
            {canConfirm && this.props.handleConfirm && <AppButton color="info" onClick={this.handleConfirm}>Confirm</AppButton>}
            {this.props.handleHide && <AppButton color="info" onClick={this.handleHide}>Delete</AppButton>}
            {canConfirm && <span style={{ flex: '1 1 0%' }} />}
            {!canConfirm && <span style={{ flex: '1 0%' }} />}
            <AppButton color="info" onClick={handleClose}>{canEdit ? 'Cancel' : 'Close'}</AppButton>
            {canEdit && <AppButton style={{ marginLeft: isXs ? 0 : '10px' }}
                onClick={() => this.handleSave()} color="secondary"
                disabled={!initialInvite?.id && !isValidOrEmptyEmail(data.email)}>
                {!initialInvite?.id ? 'Add' : 'Save'}
            </AppButton>}
        </>;
    }

    public MainInfoComponent = () => {
        const { initialInvite } = this.props;
        const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
        const fieldsDescription = this.getFieldsDescription(isXs);
        const showExtended = initialInvite?.inviteStatus != 'invite_sent';
        return <Grid container direction={'column'}>
            <Grid container justifyContent="space-around" wrap="nowrap" spacing={1} style={{ marginTop: 4 }} direction={'row'}>
                <Grid item justifyContent="space-around" xs={this.isInEditMode() ? 6 : 12}>
                    <ValidatedTextField {...fieldsDescription.email} />
                </Grid>
                {this.isInEditMode() && <Grid item xs={6}>
                    <TextField
                        error={false}
                        label="Sent"
                        variant="standard"
                        helperText=""
                        value={formatDateTime(initialInvite.inviteDate)}
                        InputLabelProps={{ shrink: true, disabled: true }}
                        margin="dense"
                        fullWidth={true}
                        onChange={() => { }}
                    />
                </Grid>}
            </Grid>
            <Grid container justifyContent="space-around" wrap="nowrap" spacing={1} direction={'row'}>
                {<Grid item xs={6}>
                    <ValidatedTextField {...fieldsDescription.firstName} />
                </Grid>}
                <Grid item justifyContent="space-around" xs={6}>
                    <ValidatedTextField {...fieldsDescription.lastName} />
                </Grid>

            </Grid>
            {showExtended && <Grid container justifyContent="space-around" wrap="nowrap" spacing={1} style={{ marginTop: 4 }} direction={'row'} >
                <Grid item justifyContent="space-around" xs={this.isInEditMode() ? 6 : 12}>
                    <TextField
                        error={false}
                        label="Status"
                        variant="standard"
                        helperText=""
                        value={makeFriendlyString(initialInvite.inviteStatus, false)}
                        InputLabelProps={{ shrink: true, disabled: true }}
                        margin="dense"
                        fullWidth={true}
                        onChange={() => { }}
                    />
                </Grid>
                {this.isInEditMode() && <Grid item xs={6}>
                    <TextField
                        error={false}
                        label="Date/Time"
                        variant="standard"
                        helperText=""
                        value={formatDateTime(initialInvite.statusDate)}
                        InputLabelProps={{ shrink: true, disabled: true }}
                        margin="dense"
                        fullWidth={true}
                        onChange={() => { }}
                    />
                </Grid>}
            </Grid>}
        </Grid>;
    };

    private isInEditMode(): boolean {
        return !!this.props.initialInvite?.id;
    }

    private getFieldsDescription(isXs: boolean): { [key: string]: ValidatedTextFieldProps } {
        const { data } = this.state;
        const { register } = this.props;
        const tfCommon = {
            onChange: this.handleFieldChange,
            InputLabelProps: { shrink: true } as InputLabelProps
        };
        const common = {
            register
        };
        function quote(s?: string | number) {
            return s == null ? '' : s;
        }
        return {
            email: {
                textFieldProps: {
                    id: 'email',
                    label: 'Email',
                    value: quote(data.email),
                    inputProps: {
                        disabled: !!data.id, // can't edit email after invitation has been sent
                    },
                    autoFocus: true,
                    ...tfCommon
                },
                rules: [emailValidation, maxLength(40)],
                ...common
            },
            firstName: {
                textFieldProps: {
                    id: 'firstName',
                    label: 'First Name',
                    value: quote(data.firstName),
                    inputProps: {
                        disabled: false, // can edit names for invites to contacts from roster list?
                    },
                    ...tfCommon
                },
                rules: [maxLength(25)],
                ...common
            },
            lastName: {
                textFieldProps: {
                    id: 'lastName',
                    label: 'Last Name',
                    value: quote(data.lastName),
                    inputProps: {
                        disabled: false, // can edit names for invites to contacts from roster list?
                    },
                    ...tfCommon
                },
                rules: [maxLength(25)],
                ...common
            }
        };
    }
}

function EditInviteDialog(props: CommonProps) {
    const userAware = useUserAware();
    return userAware.workingUserId ? <InviteDialog userId={userAware.workingUserId} {...props} /> : null;
}

export default withStyles(styles)(withForm(EditInviteDialog));
