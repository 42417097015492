import * as React from 'react';
import { Fab, Hidden, Menu, IconButton, MenuItem } from '@mui/material';
import { RoutingButton, RoutingMenuItem } from './Routing';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MouseEvent } from 'react';
import { useUserAware, } from '../auth/Auth';
import { useAppStyles } from '../styles';
import { ITEM_NEW_EVENT_ID, Urls } from '../util/config';
import { FlexCenter } from 'src/common/Misc';
import { GobletIcon, HelpIcon, PersonIcon, PlusIcon, RightArrowIcon, StarIcon } from 'src/common/Icons';
import AppButton from 'src/common/components/AppButton';
import NewEventDialog from 'src/event/list/NewEventDialogDetailed';
import DuplicateEventDialog from 'src/event/list/DuplicateEventDialog';
import { Event } from 'src/types/EventTypes';
import { useCookies } from 'react-cookie';
import ProPlanDialog from 'src/event/list/ProPlanDialog';
import { pushUrl } from 'src/redux/ReduxConfig';

function NavigationMenu() {
    const [addDialogOpened, setAddDialogOpened] = React.useState(false);
    const [duplicateEvent, setDuplicateEvent] = React.useState<Event | undefined>();
    const [proBanner, showProBanner] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
    const [_cookies, _setCookie, removeCookie] = useCookies(['announcementShown']);
    const classes = useAppStyles();
    const userAware = useUserAware();
    const { loginStatus, effectiveUserId, hasPro } = userAware;
    if (loginStatus !== 'Logged') {
        return null;
    }
    const menuOpened = Boolean(anchorEl);
    const handleMenuOpen = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(undefined);
    const handleDuplicateEvent = () => {
        setAddDialogOpened(false);
        setDuplicateEvent({ id: '' } as Event);
    }
    const handleCloseDialog = (addedId?: string) => {
        setAddDialogOpened(false);
        setDuplicateEvent(undefined);
        if (addedId) {
            localStorage.setItem(ITEM_NEW_EVENT_ID, addedId);
            window.location.replace(`/events/${addedId}`);
        }
    }
    const openHelpPage = () => {
        window.open(Urls.helpURL, 'help');
        handleMenuClose();
    }
    const handleClosePorPlan = (name?: 'learn_more' | 'upgrade') => {
        showProBanner(false);
        if (name === 'learn_more') {
            pushUrl('/account/plans');
        }
    }
    const path = window.location.pathname;
    const pathEvents = path === '/events' || path == '/events/';
    const pathInEvent = !pathEvents && path.startsWith('/events/');
    return <>
        <span color="inherit" style={{ flex: 1, fontSize: 12 }} />
        {!hasPro &&
            <AppButton
                color="inherit"
                sx={{ minWidth: 104 }}
                className={classes.toolbarButtonCommon + ' ' + classes.toolbarButtonPro}
                onClick={() => showProBanner(true)}>
                <FlexCenter><StarIcon sx={{ width: '16px', paddingRight: '4px' }} />Get Pro</FlexCenter>
            </AppButton>}
        <Hidden mdDown>
            <RoutingButton
                color="inherit"
                variant="extended"
                to="/events"
                sx={{ minWidth: 140 }}
                className={classes.toolbarButtonCommon + ' ' + (pathEvents ? classes.toolbarButtonActive : classes.toolbarButton)}>
                <FlexCenter><GobletIcon /> &nbsp; My Events</FlexCenter>
            </RoutingButton>
            <RoutingButton
                color="inherit"
                variant="extended"
                to="/account"
                sx={{ minWidth: 140 }}
                className={classes.toolbarButtonCommon + ' ' + (path.indexOf('/account') === 0 ? classes.toolbarButtonActive : classes.toolbarButton)}>
                <FlexCenter><PersonIcon /> &nbsp; Account</FlexCenter>
            </RoutingButton>
            <Fab href={Urls.helpURL}
                target="help"
                variant="extended"
                sx={{ minWidth: 124 }}
                className={classes.toolbarButtonCommon + ' ' + classes.toolbarButton}>
                <HelpIcon /> &nbsp; HELP <RightArrowIcon sx={{ height: '.5em' }} />
            </Fab>
            {!pathInEvent && <AppButton
                color="info"
                sx={{ minWidth: 124 }}
                className={classes.toolbarButtonCommon + ' ' + classes.toolbarButtonInv}
                onClick={() => setAddDialogOpened(true)}>
                <PlusIcon sx={{ width: '.7em', height: '.7em' }} /> New event
            </AppButton>}
            {effectiveUserId &&
                <RoutingButton
                    color="inherit"
                    variant="extended"
                    to="/sup"
                    className={classes.toolbarButtonCommon + ' ' + (path.indexOf('/sup') === 0 ? classes.toolbarButtonActive : classes.toolbarButton)}>SUP</RoutingButton>}
        </Hidden>
        <Hidden mdUp>
            {!pathInEvent && <AppButton
                color="info"
                sx={{ minWidth: 70 }}
                className={classes.toolbarButtonCommon + ' ' + classes.toolbarButtonInv}
                onClick={() => setAddDialogOpened(true)}>
                <PlusIcon sx={{ width: '.7em', height: '.7em' }} /> New
            </AppButton>}
            {effectiveUserId &&
                <RoutingButton
                    color="inherit"
                    variant="extended"
                    to="/sup"
                    className={classes.toolbarButtonCommon + ' ' + (path.indexOf('/sup') === 0 ? classes.toolbarButtonActive : classes.toolbarButton)}>SUP</RoutingButton>}
            <IconButton onClick={handleMenuOpen} color="inherit" size="large">
                <MoreVertIcon color="inherit" />
            </IconButton>
            <Menu
                open={menuOpened}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                slotProps={{ root: { style: { backgroundColor: 'rgba(21, 21, 21, 0.4)' } } }}
                onClose={handleMenuClose}>
                <RoutingMenuItem color="info" to="/events" onClick={handleMenuClose}>
                    <FlexCenter><GobletIcon color="secondary" /> &nbsp; My Events</FlexCenter>
                </RoutingMenuItem>
                <RoutingMenuItem color="info" to="/account" onClick={handleMenuClose}>
                    <FlexCenter><PersonIcon color="secondary" /> &nbsp; Account</FlexCenter>
                </RoutingMenuItem>
                <MenuItem onClick={openHelpPage} >
                    <HelpIcon color="secondary" /> &nbsp; Help <RightArrowIcon sx={{ height: '.5em' }} />
                </MenuItem>
            </Menu>
        </Hidden>
        <span style={{ width: 10, height: 10, position: 'absolute', left: 1, top: 1 }} onClick={() => removeCookie('announcementShown', { path: '/' })} />
        {addDialogOpened &&
            <NewEventDialog
                noDuplicate
                handleClose={handleCloseDialog}
                handleDuplicateEvent={handleDuplicateEvent}
            />}
        {duplicateEvent &&
            <DuplicateEventDialog
                event={duplicateEvent}
                handleClose={handleCloseDialog}
            />}
        {proBanner &&
            <ProPlanDialog
                handleClose={handleClosePorPlan}
            />}
    </>;
}

export default NavigationMenu;
