import * as React from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import { firebaseAuth } from '../util/firebase';
import * as Backend from '../util/firebase';
import { withProgress } from '../util/ProgressPromise';
import { pushUrl } from '../redux/ReduxConfig';
import { emailFormat } from '../util/utility';
import LabeledField from '../common/form/LabeledField';
import EditTextField from '../common/form/EditTextField';
import { FirebaseUserDataComponent } from '../common/WithData';
import { emailValidation, required } from '../validation/ValidatedTextField';
import AppButton from '../common/components/AppButton';
import ButtonBar from '../common/components/ButtonBar';
import { WithUserAware, remoteLogout, useUserAware } from './Auth';
import { useAppStyles, styles } from '../styles';
import { ListElem } from "../common/Misc";
import { PayPalSettingsDialog } from "../payments/paypal/PayPalSettingsDialog";
import { UserInfo } from "../types/EventTypes";
import { AppColors } from 'src/main/Theme';
import { StarIcon } from 'src/common/Icons';
import { DateRangeIcon } from '@mui/x-date-pickers';
import { openSessionSchedule } from 'src/util/config';
import ManageAccountDialog from './ManageAccountDialog';

function evalAccount(data: Backend.DocumentData) {
    if (!data || !data.loginId) {
        return 'Account is missing';
    }
    const loginType = data.loginId[0];
    switch (loginType) {
        case 'e': {
            return data.loginId.slice(2);
        }
        case 'a': {
            return 'Sign in with AppleID';
        }
        case 'g': {
            return 'Sign in with Google';
        }
        case 'f': {
            return 'Sign in with Facebook';
        }
        default: {
            return 'Sign in with Facebook';
        }
    }
}

async function updateAccountAndEvents(data: any): Promise<void> {
    const userId = firebaseAuth.currentUser!.uid;
    const eventSnaps = await Backend.getDocs(Backend.query(Backend.eventsDb, Backend.where('userId', '==', userId)));
    return Backend.withTransaction(async transaction => {
        transaction.update(Backend.userFields(userId), data);
        const eventData = {} as any;
        if (data.email) {
            eventData.userEmail = data.email;
        }
        if (data.name) {
            eventData.userName = data.name;
        }
        eventSnaps.forEach(eventRef => transaction.update(eventRef.ref, eventData));
    });
}

type UpdateData = {
    newName?: string;
    newEmail?: string;
    newPayPalEmailAddress?: string;
    newPayPalCurrencyCode?: String;
};

type Props = WithStyles<typeof styles> & WithUserAware;

type State = {
    account: string;
    email: string;
    oldEmail: string;
    name: string;
    oldName: string;
    hasError?: boolean;
    manageAccountOpened: boolean;
    payPalSettingsOpened: boolean;
    payPalEmailAddress: string;
    payPalCurrencyCode: string;
};

class AccountSettings extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            oldEmail: '',
            name: '',
            oldName: '',
            account: '',
            payPalEmailAddress: '',
            payPalCurrencyCode: '',
            manageAccountOpened: false,
            payPalSettingsOpened: false
        };
    }

    private handleManageEvents = () => pushUrl('/events');
    private isValidNewEmail = (newEmail: string) => firebaseAuth.currentUser && newEmail && emailFormat.test(newEmail.trim());

    private handleLogout = () => {
        const { setSigningOut } = this.props.userAware;
        setSigningOut(true);
        withProgress(firebaseAuth.signOut(), 'Logout failed ')
            .then(remoteLogout)
            .catch(() => setSigningOut(false));
    };

    private userRemoteChanged = (userInfo: UserInfo) => {
        const account = evalAccount(userInfo);
        const email = userInfo.email || firebaseAuth.currentUser?.email || '';
        let name = userInfo.name || firebaseAuth.currentUser?.displayName || '';
        if (name === '') {
            name = localStorage.getItem('displayName') ?? '';
        } else {
            localStorage.removeItem('displayName');
        }
        this.setState({
            email, oldEmail: email, payPalEmailAddress: userInfo.payPalEmailAddress ?? '',
            payPalCurrencyCode: userInfo.payPalCurrencyCode ?? '', name, oldName: name, account
        });
    };

    private update = (updateData: UpdateData) => {
        if (!firebaseAuth.currentUser) {
            return;
        }
        const { newName, newEmail, newPayPalEmailAddress, newPayPalCurrencyCode } = updateData;
        const userData = {} as any;
        if (newName) {
            userData.name = newName.trim();
        }
        if (newEmail && this.isValidNewEmail(newEmail)) {
            userData.email = newEmail.trim();
        }
        if (newPayPalEmailAddress && this.isValidNewEmail(newPayPalEmailAddress)) {
            userData.payPalEmailAddress = newPayPalEmailAddress.trim();
        }
        if (newPayPalCurrencyCode) {
            userData.payPalCurrencyCode = newPayPalCurrencyCode.trim();
        }
        withProgress(updateAccountAndEvents(userData), "Failed to update account settings!").then(() => {
            if (newName) {
                this.setState({ oldName: this.state.name });
            }
            if (newEmail && this.isValidNewEmail(newEmail)) {
                this.setState({ oldEmail: this.state.email });
            }
        });
    };

    private openManageAccount = () => this.setState({ manageAccountOpened: true });
    private closeManageAccount = () => this.setState({ manageAccountOpened: false });

    private closePayPalSettings = () => this.setState({ payPalSettingsOpened: false });
    private openPayPalSettings = () => this.setState({ payPalSettingsOpened: true });

    private onPayPalDialogSave = (newPayPalEmailAddress: string, newPayPalCurrencyCode: string) => {
        if (this.isValidNewEmail(newPayPalEmailAddress)) {
            this.update({ newPayPalCurrencyCode, newPayPalEmailAddress });
            this.closePayPalSettings();
        }
    };

    render() {
        const { classes, userAware } = this.props;
        const { payPalSettingsOpened, payPalEmailAddress, payPalCurrencyCode, manageAccountOpened, email } = this.state;
        const payPalTitle = payPalEmailAddress || "No email";
        const payPalSubtitle = "A verified PayPal email address to accept golfer payments.";
        return (
            <div style={{ width: '100%' }} className={classes.listRootGreyRound}>
                <List disablePadding>
                    {!userAware.hasPro &&
                        <ListItem className={classes.accountSettingsFree} sx={{ backgroundColor: AppColors.webGreen100 }}>
                            <span>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>You’re currently on a Free Plan</Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>Upgrade your plan to create multi-day events and register golfers without limits</Typography>
                            </span>
                            <span style={{ flex: '1 1 0%' }} />
                            <AppButton
                                sx={{ minWidth: '130px' }}
                                className={classes.toolbarButtonCommon + ' ' + classes.toolbarButtonPro2}
                                onClick={() => pushUrl('/account/plans')}>
                                <StarIcon sx={{ width: '16px', paddingRight: '4px' }} /> Learn more
                            </AppButton>
                        </ListItem>}
                    <LabeledField
                        noIcon
                        label="Golf Pad account"
                        value={<>
                            {this.state.account}
                            <AppButton sx={{ float: 'right' }} color="info" onClick={this.openManageAccount}>
                                Manage account
                            </AppButton>
                        </>}
                    />
                    <LabeledField
                        noIcon
                        label="License"
                        value={<>
                            {userAware.hasPro ? 'Events Pro' : 'Free Plan'}
                            <AppButton sx={{ float: 'right' }} color="info" onClick={() => pushUrl('/account/plans')}>
                                Manage subscription
                            </AppButton>
                        </>}
                    />
                    {userAware.hasPro &&
                        <ListItem className={classes.accountSettingsFree} sx={{ backgroundColor: AppColors.webGreen100 }}>
                            <span>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>You’re currently on a Events Pro Plan</Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                                    The Golf Pad team can be your on-hand resource as you create your golf tournament from start to finish.
                                    Schedule a video call with the Golf Pad Events team today!
                                </Typography>
                            </span>
                            <span style={{ flex: '1 1 0%' }} />
                            <AppButton
                                sx={{ minWidth: '130px' }}
                                className={classes.toolbarButtonCommon + ' ' + classes.toolbarButtonPro2}
                                onClick={openSessionSchedule}>
                                <DateRangeIcon sx={{ width: '16px', paddingRight: '4px' }} /> Book session
                            </AppButton>
                        </ListItem>}
                    <EditTextField
                        label="Name"
                        value={this.state.name}
                        rules={[required]}
                        textFieldProps={{}}
                        save={newName => this.update({ newName })}
                    />
                    <EditTextField
                        label="Communication e-mail"
                        hint="Use this e-mail to communicate with tournament participants"
                        textFieldProps={{}}
                        value={this.state.email}
                        rules={[emailValidation]}
                        save={newEmail => this.update({ newEmail })}
                    />
                    <LabeledField
                        label="PayPal settings"
                        value={(<ListElem id="paypal_settings"
                            title={payPalTitle}
                            subtitle={payPalSubtitle}
                            titleColor={payPalEmailAddress ? undefined : '#949494'}
                        />)}
                        edit={this.openPayPalSettings}
                    />
                    {payPalSettingsOpened && <PayPalSettingsDialog
                        emailAddress={payPalEmailAddress}
                        currencyCode={payPalCurrencyCode}
                        onClose={this.closePayPalSettings}
                        onSave={this.onPayPalDialogSave}
                    />}
                    <ManageAccountDialog
                        email={email}
                        open={manageAccountOpened}
                        handleLogout={this.handleLogout}
                        onClose={this.closeManageAccount}
                    />
                </List>
                <ButtonBar>
                    <AppButton color="secondary" className={classes.button} onClick={this.handleManageEvents}>
                        Manage events
                    </AppButton>
                </ButtonBar>
                <FirebaseUserDataComponent onData={this.userRemoteChanged} />
            </div>
        );
    }
}

export default function() {
    const classes = useAppStyles();
    const userAware = useUserAware();
    return <AccountSettings classes={classes} userAware={userAware} />;
}
