import * as React from 'react';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { pushUrl } from '../redux/ReduxConfig';
import { drawerWidth } from '../styles';
import { PlansIcon, SettingsIcon } from 'src/common/Icons';
import { AppColors } from 'src/main/Theme';
import AccountSettings from './AccountSettings';
import AccountPlans from './AccountPlans';

function Account() {
    const location = useLocation();
    function action(_name: 'learn_more' | 'upgrade') {
        // TODO
    }
    return (
        <div style={{ display: 'flex' }}>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}>
                <Box sx={{ height: 56 }} />
                <List>
                    <ListItem disablePadding>
                        <ListItemText primary={'Account'} primaryTypographyProps={{ fontSize: '16px', fontWeight: 500 }} />
                    </ListItem>
                    <ListItem sx={{ padding: 0 }}>
                        <ListItemButton sx={{ backgroundColor: location.pathname === '/account/settings' ? AppColors.webBlue100 : '' }}
                            onClick={() => pushUrl('/account/settings')}>
                            <ListItemIcon><SettingsIcon /></ListItemIcon>
                            <ListItemText primary={'Settings'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem sx={{ padding: 0 }}>
                        <ListItemButton sx={{ backgroundColor: location.pathname === '/account/plans' ? AppColors.webBlue100 : '' }}
                            onClick={() => pushUrl('/account/plans')}>
                            <ListItemIcon><PlansIcon /></ListItemIcon>
                            <ListItemText primary={'Plans'} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Routes>
                <Route path="settings" element={<AccountSettings />} />
                <Route path="plans" element={<AccountPlans variant="long" action={action} />} />
                <Route path="*" element={<Navigate to={'settings'} />} />
            </Routes>
            <Outlet />
        </div>
    );
}

export default Account;
