import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { WithStyles } from '@mui/styles';
import { Chip, DialogContent, Box, Grid, Button, Typography, TextField, FormControl, ClickAwayListener, Popper, Paper, Tooltip } from '@mui/material';
import { Link, Share, Person, PersonAddAlt1, Send, HowToReg, CheckCircle } from '@mui/icons-material';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import { getRegistrationDate, Event, EventData, Contact, ContactDetails, SpreadsheetImportResult, MAX_INVITES_COUNT } from '../../../types/EventTypes';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { Urls } from '../../../util/config';
import { processArrowDownKey, processArrowUpKey, processEnterKey, processEscKey, processSpaceKey, processBackSpaceKey } from "../../../util/react_utils";
import { emailFormat, withS, formatDateDashed1, DAY_MILLIS } from "../../../util/utility";
import * as Backend from '../../../util/firebase';
import { AppColors } from "../../../main/Theme";
import { showProgress, showError, ProgressFunction } from '../../../redux/ReduxConfig';
import MaterialDate from '../../../common/MaterialDate';
import { ListItemX, Spacing } from '../../../common/Misc';
import AppButton from '../../../common/components/AppButton';
import { EVENT_DATETIME_FORMAT_DLG } from 'src/util/config';
import { ContactGroup, elog } from '../../Event';
import { contactName, getRosterContactMatcher, emailOrName, indexOfContact } from '../../../contact/Contact';
import ImportContactDialog from "../../../contact/import/ImportContactDialog";
import AddGolfersDialog from './AddGolfersDialog';
import { RWebShare } from "react-web-share";
import { isMobile } from 'react-device-detect';

interface ContactProps {
    contact: ContactDetails;
    selected: boolean;
    added: boolean;
    reged: boolean;
    onSelect: (contact: ContactDetails) => void;
    onAddToEvent: (contact: ContactDetails) => void;
}

const Icon = withStyles(styles)((props: WithStyles<typeof styles> & { contact: ContactDetails }) => {
    const { contact, classes } = props;
    const iconClassName = !!contact.email ? classes.listAvatar : classes.listAvatarDisabled;
    return contact.avatar
        ? <img src={contact.avatar} className={classes.listAvatar} alt="" />
        : <Person className={iconClassName} />
});

const ContactItem = withStyles(styles)((props: ContactProps & WithStyles<typeof styles>) => {
    const { classes, contact, onSelect, onAddToEvent, selected, added, reged } = props;
    const name = contactName(contact);
    const disabled = added || reged;
    const decorations = classes.listItem + (disabled && !selected ? (' ' + classes.disabled) : '') + (selected ? (' ' + classes.selected) : '');
    return (
        <ListItemX
            button={!!contact.email}
            onClick={() => {
                if (!!contact.email) {
                    onSelect(contact);
                }
            }}
            className={decorations}>
            <Grid container alignItems="center" xs={12}>
                <Grid xs={3} md={2}>
                    <Icon contact={contact} />
                </Grid>
                <Grid container direction={'column'} xs={disabled ? 8 : 9} md={disabled ? 9 : !!contact.email ? 10 : 4}>
                    <Grid>
                        <Typography sx={{ marginLeft: '-16px' }}>{name.length > 0 ? name : contact.email}</Typography>
                    </Grid>
                    <Grid>
                        {!!contact.email && <Typography variant="caption" sx={{ marginLeft: '-16px', color: '#225987', fontSize: '12px' }}>
                            {contact.email}
                        </Typography>}
                        {!contact.email && <Typography variant="caption" sx={{ marginLeft: '-16px', color: 'red', fontSize: '12px' }}>
                            no email
                        </Typography>}
                    </Grid>
                </Grid>
                {!contact.email && !disabled && <Grid container xs={12} md={6} display="flex" flexDirection="row" alignItems="right" justifyContent="end">
                    <Grid item display="flex" flexDirection="row" gap="4px" onClick={() => {
                        onAddToEvent(contact);
                    }} className={classes.clickable}>
                        <PersonAddAlt1 color="secondary" />
                        <Typography className={classes.secondaryText} noWrap>Add as a confirmed participant</Typography>
                    </Grid>
                </Grid>}
                {disabled && <Grid item xs={1} display="flex" flexDirection="row" alignItems="right" justifyContent="end">
                    {reged ? <Tooltip title="Listed as playing"><HowToReg color="disabled" /></Tooltip> : <Tooltip title="Already added to the reciepient list"><CheckCircle color="disabled" /></Tooltip>}
                </Grid>}
            </Grid>
        </ListItemX>
    );
});

export interface RosterListProps {
    contacts: Array<ContactDetails>;
    invitesIds: string[];
    participantsIds: string[];
    onSelect: (contact: ContactDetails) => void;
    onAddToEvent: (contact: ContactDetails) => void;
    selectedIdx?: number;
}

const RosterList = withStyles(styles)((props: RosterListProps & WithStyles<typeof styles>) => {
    const renderContactItem = (contact: ContactDetails, key: number, added: boolean, reged: boolean) => {
        const { onSelect, onAddToEvent, selectedIdx, classes } = props;
        return (
            <React.Fragment key={String(key)}>
                <React.Fragment key={contact.id}>
                    <ContactItem contact={contact} onSelect={onSelect} onAddToEvent={onAddToEvent} selected={key === selectedIdx} added={added} reged={reged} classes={classes} />
                </React.Fragment>
            </React.Fragment>
        );
    }
    const { contacts, invitesIds, participantsIds } = props;
    return (
        <React.Fragment>
            {contacts.map((contact, idx) => renderContactItem(contact, idx, invitesIds.findIndex(id => id === contact.id) > -1, participantsIds.findIndex(id => id === contact.id) > -1))}
        </React.Fragment>
    );
});

type RosterListWrappedProps = {
    onAddToEvent: (contact: ContactDetails) => void,
    onSelect: (contact: ContactDetails) => void,
    rosterList: Array<ContactDetails>,
    invitesIds: Array<string>,
    participantsIds: Array<string>,
    onClickAway: () => void
    selectedIdx?: number;
};

const RosterListWrapped = withStyles(styles)((props: RosterListWrappedProps & WithStyles<typeof styles>) => {
    const { onSelect, onAddToEvent, rosterList, selectedIdx, classes, invitesIds, participantsIds } = props;
    const selectedIndex = (selectedIdx ?? 0) % rosterList.length;
    return (
        <Paper style={{ overflow: 'auto', marginTop: 10, maxHeight: 400 }}>
            <Spacing />
            <RosterList contacts={rosterList} invitesIds={invitesIds} participantsIds={participantsIds} onSelect={onSelect} onAddToEvent={onAddToEvent} selectedIdx={selectedIndex} classes={classes} />
            <Spacing />
        </Paper>
    );
});

type Props = WithStyles<typeof styles> & {
    open: boolean;
    event: Event;
    eventData: EventData;
    handleCancel: () => void;
    handleSendInvite: (recipients: ContactDetails[]) => void;
    handleAddToEvent: (contact: ContactDetails) => void;
}

type State = {
    importingFromSpreadsheet: boolean;
    importingFromRoster: boolean;
    inputState: boolean;
    inputValue: string;
    recipients: Array<ContactDetails>;
    filteredRoster: Array<ContactDetails>;
    inputHelper?: string;
    rosterSelectedIdx?: number;
    registrationDeadline: Date;
    maxFollowUpOffset: number;
    followUpOffset?: number;
};

class InviteGolfersDialog extends React.Component<Props, State> {

    private readonly inputRef: React.RefObject<HTMLDivElement> = React.createRef();
    private readonly inputBoxRef: React.RefObject<HTMLDivElement> = React.createRef();

    readonly state: State;
    constructor(props: Props) {
        super(props);
        const registrationDeadline = new Date(getRegistrationDate(props.event));
        const eventFollowUpAt = props.event.followUpAt??getRegistrationDate(props.event);
        const maxFollowUpOffset = Math.floor((registrationDeadline.valueOf() - Date.now()) / DAY_MILLIS);
        const followUpOffset = Math.floor((registrationDeadline.valueOf() - eventFollowUpAt) / DAY_MILLIS);
        this.state = {
            importingFromSpreadsheet: false,
            importingFromRoster: false,
            inputState: false,
            inputValue: '',
            recipients: [],
            filteredRoster: Array.from(props.eventData.roster.values()),
            registrationDeadline: registrationDeadline,
            maxFollowUpOffset: maxFollowUpOffset,
            followUpOffset: followUpOffset > 0 ? followUpOffset : undefined
        };
    }

    private handleCancel = () => this.props.handleCancel();

    private handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { eventData } = this.props;
        const { recipients } = this.state;
        let inputValue = e.target.value;
        const filteredRoster = inputValue?.length > 0
            ? Array.from(eventData.roster.values()).filter(getRosterContactMatcher(inputValue))
            : Array.from(eventData.roster.values());
        if (filteredRoster.length === 0) {
            if (!!inputValue && inputValue.length > 0) {
                const arrayText = inputValue.split(/[ ,;]+/);
                if (arrayText.length > 1) {
                    for (let index in arrayText) {
                        const cleanText = arrayText[index].trim();
                        if (emailFormat.test(cleanText)) {
                            const contactToAdd = { email: cleanText, lastName: '', hidden: false, id: '' };
                            const { alreadyRecipient, emailAlreadyInRecipientsList } = this.checkAbililityToAddContact(contactToAdd);
                            if (!alreadyRecipient && !emailAlreadyInRecipientsList) {
                                recipients.push(contactToAdd);
                                inputValue = inputValue.replace(cleanText, "");
                            }
                        }
                    }
                    inputValue = inputValue.replace(" ", "").replace(",", "").replace(";", "");
                } else {
                    const cleanText = inputValue!.trim();
                    if (emailFormat.test(cleanText)) {
                        filteredRoster.push({ email: cleanText, lastName: '', hidden: false, id: '' });
                    }
                }
            }
        }
        this.setState({ filteredRoster: filteredRoster, inputValue, rosterSelectedIdx: 0, inputState: true, inputHelper: undefined });
    }
    
    private handleChangeFollowUp = (event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { maxFollowUpOffset } = this.state;
        if (event.target.value.match(/[^0-9]/)) {
            this.setState({ followUpOffset: undefined });
        } else {
            var value = parseInt(event.target.value, 10);
            if (value > maxFollowUpOffset) value = maxFollowUpOffset;
            if (value < 1) value = 1;
            this.setState({ followUpOffset: value });
        }
    }
    
    private handleChangeRegistrationDeadline = (date : Date) => {
        const maxFollowUpOffset = Math.floor((date.valueOf() - Date.now()) / DAY_MILLIS);
        this.setState({ registrationDeadline: date, maxFollowUpOffset: maxFollowUpOffset })
    }
    
    private handleEscDirectInput = () => {
        this.setState({ inputState: false, inputValue: '', rosterSelectedIdx: 0 });
    }

    private handleShiftPaperSelectionDown = () => {
        this.setState({ rosterSelectedIdx: (this.state.rosterSelectedIdx ?? 0) + 1 });
    }

    private handleShiftPaperSelectionUp = () => {
        this.setState({ rosterSelectedIdx: (this.state.rosterSelectedIdx ?? 0) - 1 });
    }

    private handleDelDirectInput = () => {
        const { recipients, inputValue } = this.state;
        if (inputValue.length == 0 && recipients.length > 0) {
            recipients.splice(recipients.length - 1)
            this.setState({ recipients: recipients });
        }
    }

    private handleSubmitPaper = () => {
        const { filteredRoster, rosterSelectedIdx } = this.state;
        const index = Math.abs(rosterSelectedIdx ?? 0) % filteredRoster.length;
        if (index >= 0) {
            const recipient = filteredRoster[index];
            this.handleSubmitRecipient(recipient)

        } else {
            this.setState({ inputHelper: 'Invalid Email.' });
        }
    }

    private handleSubmitRecipient = (recipient: Contact) => {
        const { eventData } = this.props;
        const { recipients, inputValue } = this.state;
        const { alreadyRecipient, emailAlreadyInRecipientsList } = this.checkAbililityToAddContact(recipient);
        if (alreadyRecipient) {
            showError('Contact already invited to the event.');
        } else if (emailAlreadyInRecipientsList) {
            showError('Contact with such email already invited to the event.');
        } else if (recipients.length >= this.evalFreeSlots()) {
            showError('Max invites count exceed.');
        } else {
            recipients.push(recipient);
            const filteredRoster = inputValue?.length > 0
                ? Array.from(eventData.roster.values()).filter(getRosterContactMatcher(inputValue))
                : Array.from(eventData.roster.values());
            this.setState({ filteredRoster, recipients, inputValue: '', rosterSelectedIdx: 0 });
        }
    }

    private handleDeleteRecipient = (recipient: Contact) => {
        const recipients = this.state.recipients;
        recipients.splice(indexOfContact(recipients, recipient.id), 1);
        this.setState({ recipients });
    }

    private recipientsToChips = () => {
        const { classes } = this.props;
        const { recipients } = this.state;
        return recipients.map(recipient => (
            <Chip key={recipient.id}
                label={emailOrName(recipient)}
                className={classes.chip}
                onDelete={() => this.handleDeleteRecipient(recipient)} />));
    }

    private evalFreeSlots = () => {
        const { eventData } = this.props;
        const { recipients } = this.state;
        return MAX_INVITES_COUNT - eventData.invitedContacts.size - recipients.length;
    }

    private checkAbililityToAddContact = (contact: Contact) => {
        const { eventData } = this.props;
        const { recipients } = this.state;
        const golfersList = Array.from(eventData.golfersAggregated.values());
        const invitesList = Array.from(eventData.invitedContacts.values());
        const emailAlreadyInRecipientsList = recipients.findIndex(r => r.email === contact.email) > -1
            || invitesList.findIndex(c => c.email === contact.email) > -1
            || golfersList.findIndex(g => g.email === contact.email) > -1;
        const recipientIds = recipients.filter(r => !!r.id).map(r => r.id);
        const invitesIds = invitesList.map(i => i.id);
        const participantsIds = golfersList.map(p => p.id);
        const alreadyRecipient = invitesIds.findIndex(id => id === contact.id) > -1
            || recipientIds.findIndex(id => id === contact.id) > -1
            || participantsIds.findIndex(id => id === contact.id) > -1;
        return { emailAlreadyInRecipientsList, alreadyRecipient }
    }


    private handleAddFromRoster = (contacts: Array<ContactGroup>) => {
        const recipients = this.state.recipients;
        const contactDetails: ContactDetails[] = contacts
            .filter(contactItem => contactItem.contact && recipients.findIndex(c => c.id === contactItem.contact!.id) === -1)
            .map(contactItem => {
                return {
                    ...contactItem.contact!,
                    hidden: false,
                    lastName: contactItem.contact?.lastName ?? ''
                } as ContactDetails
            })
            .slice(0, this.evalFreeSlots() - recipients.length);;
        recipients.push(...contactDetails);
        this.setState({ importingFromRoster: false, recipients });
    }

    private handleImportResults = (result: SpreadsheetImportResult, progress?: ProgressFunction) => {
        const recipients = this.state.recipients;
        const alreadyInEvent = Array.from(this.props.eventData.invitedContacts.values()).map(invite => { return { email: invite.email } as ContactDetails })
        alreadyInEvent.push(...Array.from(this.props.eventData.golfersAggregated.values()));
        const contactDetails: ContactDetails[] = result.contacts
            .filter(contactItem => contactItem && recipients.findIndex(c => c.email === contactItem.email) === -1 && alreadyInEvent.findIndex(c => c.email === contactItem.email) === -1)
            .map(contactItem => {
                return {
                    ...contactItem,
                    hidden: false,
                    lastName: contactItem.lastName ?? ''
                } as ContactDetails
            })
            .slice(0, this.evalFreeSlots() - recipients.length);
        recipients.push(...contactDetails);
        const notificationMessage = `${withS(contactDetails.length, 'email')} added to the recipient list` +
            (result.rejected > 0 || result.added - contactDetails.length > 0 ? `, others ${withS(result.rejected + result.added - contactDetails.length, 'email')} skeeped.` : '.')
        this.setState({ importingFromSpreadsheet: false, recipients }, () => progress ? progress(notificationMessage) : {});
    }

    private handleCancelAddFromRoster = () => this.setState({ importingFromRoster: false });

    private handleSave = async () => {
        const { event } = this.props;
        const { registrationDeadline, followUpOffset } = this.state;
        const eventFollowUpAt = event.followUpAt ?? 0
        if (registrationDeadline.valueOf() != getRegistrationDate(event) 
            || followUpOffset != (getRegistrationDate(event) - eventFollowUpAt)) {
            const eventUpdate = { id: event.id, exists: true } as Event;
            if (registrationDeadline.valueOf() != event.registrationDeadline) {
                eventUpdate.registrationDeadline = registrationDeadline.valueOf();
            }
            if (!!followUpOffset && (followUpOffset * DAY_MILLIS != (getRegistrationDate(event) - eventFollowUpAt))) {
                eventUpdate.followUpAt = getRegistrationDate(event) - followUpOffset! * DAY_MILLIS;
            }
            if (!followUpOffset && event.followUpAt) {
                eventUpdate.followUpAt = null;
            }            
            await Backend.withTransaction(async transaction => {
                const hideProgress = showProgress('InviteGolfersDialog handleSave');
                try {
                    Backend.updateInTransaction(Backend.eventsDb, eventUpdate, transaction);
                    elog(event, `Registration settings modified`, `RegistrationDeadline till ${formatDateDashed1(registrationDeadline.valueOf())} followUpOffset ${followUpOffset}`, `Id: ${event.id}`);
                    return Promise.resolve();
                } finally {
                    hideProgress();
                }
            });
        }
    };
    
    private handleSend = async () => {
        this.handleSave();
        this.props.handleSendInvite(this.state.recipients);
    }    

    render() {
        const { classes, open, event, eventData, handleAddToEvent } = this.props;
        const { importingFromRoster, importingFromSpreadsheet, inputValue, inputState, inputHelper, filteredRoster, recipients, rosterSelectedIdx, registrationDeadline, followUpOffset } = this.state;
        const label = 'INVITE GOLFERS';
        const registrationLink = Urls.makeAboutLink(event.publicId);
        const id = inputState ? 'roster-popper' : undefined;
        const alreadyRecipient = recipients.filter(r => !!r.id).map(r => r.id);
        const invitesIds = Array.from(eventData.invitedContacts.values()).map(i => i.id);
        invitesIds.push(...alreadyRecipient);
        const participantsIds = Array.from(eventData.golfersAggregated.values()).map(p => p.id);
        const popperOpened = Boolean(inputState) && Boolean(inputValue.length > 0) && Boolean(this.inputBoxRef.current) && Boolean(filteredRoster.length > 0);
        const placeholder = recipients.length ?? 0 ? '' : "Email, name, email list";
        const selectedGolfersIds = recipients.map(contact => contact.id);
        const excludedGolfersIds = Array.from(eventData.invitedContacts.values()).map(invite => invite.id);
        const maxFollowUpOffset = Math.floor((registrationDeadline.valueOf() - Date.now()) / DAY_MILLIS);
        const followUpOffsetDisabled = maxFollowUpOffset < 1;
        const notListedRoster = new Map<string, ContactDetails>()

        const saveButtonDisabled = this.state.registrationDeadline.valueOf() == getRegistrationDate(this.props.event)
            && ((!event.followUpAt && !followUpOffset) || (!!followUpOffset && event.followUpAt == getRegistrationDate(event) - followUpOffset! * DAY_MILLIS))
        eventData.roster.forEach((contact, key) => {
            if (!eventData.golfersAggregated.has(key)) {
                notListedRoster.set(key, contact);
            }
        }) // filter(contact => !eventData.golfers.has(contact.id))
        if (inputState) {
            this.inputRef.current?.focus();
        }
        return (
            <React.Fragment>
                {!importingFromRoster && <XSMobileDialog fullWidth={true} open={open} onClose={this.handleCancel} maxWidth={'md'}>
                    <DialogAppBar label={label} close={this.handleCancel} />
                    <DialogContent>
                        <Grid style={{ marginTop: 10 }} container>
                            <Grid item xs={6} style={{ textAlign: 'left' }}>
                                <Typography noWrap>{isMobile ? 'Share registration link' : 'Share self-registration link'}</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {!isMobile && <CopyToClipboard text={registrationLink} onCopy={() => showError('Link copied to the clipboard')}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        style={{ fontSize: '0.85rem', marginTop: -10, padding: '4px 24px', borderRadius: '4px' }}>
                                        <Typography paddingRight={'4px'} variant="subtitle2" noWrap>Copy link</Typography>
                                        <Link className={classes.rotate135} />
                                    </Button>
                                </CopyToClipboard>}
                                {isMobile && <RWebShare
                                    data={{
                                        text: "Self-registration link",
                                        url: registrationLink,
                                        title: "Share self-registration link",
                                    }}
                                    onClick={() => showError('Sucessfully shared')}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        style={{ fontSize: '0.85rem', marginTop: -10, padding: '4px 24px', borderRadius: '4px' }}>
                                        <Typography paddingRight={'4px'} variant="subtitle2" noWrap>Share</Typography>
                                        <Share />
                                    </Button>
                                </RWebShare>}
                            </Grid>
                        </Grid>
                        <Spacing />
                        <Box sx={{ backgroundColor: '#F5F5F5' }} padding={'15px'} display="flex" justifyContent="center" flexDirection="column" borderRadius="10px">
                            <Grid container>
                                <Grid item xs={6} style={{ textAlign: 'left' }}>
                                    <Typography noWrap>Golfers to invite</Typography>
                                </Grid>
                                <Grid item xs={6} display="flex" flexDirection="row" alignItems="right" justifyContent="end"
                                    onClick={() => this.setState({ importingFromSpreadsheet: true })} className={classes.clickable}>
                                    <img style={{ color: '#F5F5F5' }} src={Urls.uploadPrimaryIcon24} alt="" />
                                    <Typography className={classes.secondaryText} noWrap>Import from spreadsheet</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ marginTop: 10 }} container>
                                <ClickAwayListener onClickAway={() => this.setState({ inputState: false, inputValue: '', inputHelper: undefined })}><div style={{ width: '100%' }}>
                                    <FormControl variant="standard" margin="dense" sx={{ width: '98%' }}>
                                        <Box sx={{ backgroundColor: AppColors.white, width: '100%', borderColor: inputState ? AppColors.webBlue300 : AppColors.webGrey300, cursor: 'pointer' }}
                                            ref={this.inputBoxRef}
                                            margin="dense"
                                            borderRadius="4px"
                                            border={1}
                                            padding={'4px 8px'}
                                            onClick={() => {
                                                this.setState({ inputState: true })
                                            }
                                            }>
                                            {this.recipientsToChips()}
                                            <TextField
                                                inputRef={this.inputRef}
                                                type="text"
                                                hiddenLabel
                                                placeholder={placeholder}
                                                value={inputValue}
                                                helperText={inputHelper}
                                                error={!!inputHelper}
                                                variant="standard"
                                                sx={{ marginTop: '4px', marginLeft: '8px' }}
                                                onKeyDown={e => {
                                                    processArrowDownKey(e, this.handleShiftPaperSelectionDown, !inputState);
                                                    processArrowUpKey(e, this.handleShiftPaperSelectionUp, !inputState);
                                                    // processTabKey(e, this.handleSubmitPaper, !inputState);
                                                    processEscKey(e, this.handleEscDirectInput);
                                                    processEnterKey(e, inputState ? this.handleSubmitPaper : () => { });
                                                    processSpaceKey(e, inputState ? this.handleSubmitPaper : () => { });
                                                    processBackSpaceKey(e, this.handleDelDirectInput, inputValue.length > 0);
                                                }}
                                                onChange={this.handleInput}
                                                onFocus={() => this.setState({ inputState: true, filteredRoster: filteredRoster })}
                                                InputProps={{ autoComplete: 'off', disableUnderline: true }} />
                                        </Box>
                                    </FormControl>
                                    <Popper
                                        id={id}
                                        sx={{ zIndex: 1300, width: this.inputBoxRef.current?.clientWidth ?? 150 }}
                                        placement='bottom-start'
                                        open={popperOpened}
                                        anchorEl={this.inputBoxRef.current}
                                        onKeyDown={e => {
                                            processEscKey(e, this.handleEscDirectInput);
                                        }}>
                                        <RosterListWrapped
                                            onAddToEvent={handleAddToEvent}
                                            onSelect={(contact) => {
                                                if (recipients.length < this.evalFreeSlots()) {
                                                    this.handleSubmitRecipient(contact);
                                                } else {
                                                    showError('Max invites count exceed.');
                                                }
                                            }}
                                            rosterList={filteredRoster}
                                            invitesIds={invitesIds}
                                            participantsIds={participantsIds}
                                            onClickAway={() => { }}
                                            selectedIdx={rosterSelectedIdx}
                                        />
                                    </Popper>
                                </div></ClickAwayListener>
                            </Grid>
                            <Grid style={{ marginTop: 20 }} container>
                                <AppButton color="info" onClick={() => this.setState({ importingFromRoster: true })} onFocus={() => this.setState({ inputState: false, inputValue: '', inputHelper: undefined })}>
                                    Add from past events
                                </AppButton>
                            </Grid>
                            <Grid style={{ marginTop: 20 }} container spacing={1} direction={'row'}>
                                <Grid item sm={6} xs={12} style={{ textAlign: 'left' }}>
                                    <FormControl
                                        variant="standard"
                                        margin="dense"
                                        fullWidth>
                                        <MaterialDate icon enableUnderline label="Registration deadline – through"
                                            popperPlacement={'bottom'} style={{ width: '100%' }}
                                            maxDate={event.date}
                                            value={registrationDeadline.valueOf()} format={EVENT_DATETIME_FORMAT_DLG}
                                            onChange={this.handleChangeRegistrationDeadline}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6} xs={12} display="flex" flexDirection="row" alignItems="right" justifyContent="end">
                                    <FormControl
                                        variant="standard"
                                        margin="dense"
                                        fullWidth>
                                        <TextField type="number" variant="standard" label="Automatically follow-up" id="days-before" placeholder="Days before the event"
                                            InputLabelProps={{ shrink: true }} InputProps={{ autoComplete: 'off', inputProps: { min: 1, max: maxFollowUpOffset } }}
                                            value={followUpOffset} onKeyDown={e => { }} onChange={this.handleChangeFollowUp} style={{ width: '100%' }} disabled={followUpOffsetDisabled} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid style={{ marginTop: 20 }} container>
                                <Grid container spacing={1} display="flex" flexDirection="row" alignItems="right" justifyContent="flex-end">
                                    <Grid item xs>
                                        <AppButton color="info" onClick={this.handleSave} disabled={saveButtonDisabled}>
                                            Save
                                        </AppButton>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <AppButton disabled={!recipients.length} color="secondary" onClick={this.handleSend}>
                                            <Send className={classes.leftButtonIcon} />
                                            {recipients.length > 0 ? `Invite (${recipients.length})` : 'Invite'}
                                        </AppButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </XSMobileDialog>}
                {importingFromRoster && <AddGolfersDialog
                    open={importingFromRoster}
                    label={'INVITE GOLFERS'}
                    okLabel={'Save'}
                    {...{
                        eventOrRound: event,
                        golfers: notListedRoster,
                        teams: new Map(),
                        groups: [],
                        roster: eventData.roster,
                        loadedTeams: 1,
                        loadedGroups: 1,
                        loadedGolfers: 1,
                        loadedRoster: 1
                    }}
                    golferDB={'GOLFERS_EMAILS'}
                    handleAddGolfers={this.handleAddFromRoster}
                    handleCancel={this.handleCancelAddFromRoster}
                    selectedGolfersIds={selectedGolfersIds}
                    excludedGolfersIds={excludedGolfersIds} />}
                {importingFromSpreadsheet && <ImportContactDialog
                    open={importingFromSpreadsheet}
                    maxRecords={this.evalFreeSlots()}
                    importResult={this.handleImportResults}
                    handleCancel={() => this.setState({ importingFromSpreadsheet: false })} />}
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(InviteGolfersDialog);