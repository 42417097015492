import axios from 'axios';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { dbgLog, emailFormat, getServerErrorMessage } from 'src/util/utility';
import * as Backend from 'src/util/firebase';
import { Urls } from 'src/util/config';
import { showProgress } from 'src/redux/ReduxConfig';
import { remoteLogin } from './Auth';

function decodeToken(token: string) {
    let tokenData = token.split('.')[1];
    tokenData = tokenData.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(tokenData));
}

async function signInWithToken(token: string) {
    const firebaseAuth = Backend.firebaseAuth;
    const decodedToken = decodeToken(token);
    localStorage.setItem('displayName', decodedToken.name);
    const userCredentials = await Backend.signInWithCustomToken(firebaseAuth, token);
    const additionalUserInfo = Backend.getAdditionalUserInfo(userCredentials);
    const newSignin = additionalUserInfo?.isNewUser;
    Backend.trackEvent(newSignin ? 'user_sign_up_x' : 'user_sign_in_x');
    if (firebaseAuth.currentUser) {
        Backend.trackEvent(newSignin ? 'user_sign_up' : 'user_sign_in');
        await firebaseAuth.currentUser.reload();
    }
    if (firebaseAuth.currentUser) {
        if (emailFormat.test(decodedToken.email.trim())) {
            const idToken = await firebaseAuth.currentUser.getIdToken(false);
            await axios.post(Urls.checkAccessCode, { token: idToken, name: decodedToken.name, email: decodedToken.email });
        }
        if (decodedToken.loginId?.length > 0) {
            await Backend.setUserDataPromise({ loginId: decodedToken.loginId, email: decodedToken.userEmail ?? decodedToken.email });
        }
    } else {
        remoteLogin();
    }
}

export default function LoginRedirect() {
    const [loggingIn, setLoggingIn] = React.useState(false);
    const { token } = useParams<'token'>();
    const navigate = useNavigate();
    if (!token) {
        return null;
    }
    React.useEffect(() => {
        async function login() {
            if (loggingIn) {
                return;
            }
            setLoggingIn(true);
            const hideProgress = showProgress('signInWithToken');
            try {
                dbgLog('signInWithToken...');
                await signInWithToken(token!);
                hideProgress();
                navigate('/events', { replace: true });
            } catch (err) {
                dbgLog('Failed to sign in: ' + getServerErrorMessage(err));
                hideProgress('Failed to sign in: ' + getServerErrorMessage(err));
                navigate('/login', { replace: true });
            }
            setLoggingIn(false);
        }
        login();
    })
    return null;
}
